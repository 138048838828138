export const local_dsReviewClasses = {
    id: 'local_dsReviewClasses',
    viewName: 'aviw_Arena_ReviewClasses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" }]

},
local_dsClassificationCode = {
    id: 'local_dsClassificationCodes',
    viewName: 'aviw_Assets_ClassificationCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string" }]
},
local_dsTopics = {
    id: 'local_dsTopics',
    viewName: 'aviw_Arena_TopicsLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "NameAndDescription", type: "string" }]
},
local_dsProjectCodes = {
    id: 'local_dsProjectCodes',
    viewName: 'aviw_Assets_ProjectCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "OrgUnit", type: "string" }]
},
local_dsDisciplines = {
    id: 'local_dsDisciplines',
    viewName: 'aviw_Assets_DisciplinesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string" }]
},
local_dsProjectPhases = {
    id: 'local_dsProjectPhases',
    viewName: 'atbv_Complete_ProjectPhases',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
},
local_dsMainAreas = {
    id: 'local_dsMainAreas',
    viewName: 'aviw_Assets_MainAreasLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
},
local_dsPersonsLkp = {
    id: 'local_dsPersonsLkp',
    viewName: 'sviw_System_PersonsLookupWithRoles',
    maxRecords: 25,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    dynamicLoading: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "MobileNo", type: "string" },
        {name: "Email", type: "string" },
        {name: "Represents", type: "string" }]
},
local_dsOrgUnitsLkp = {
    id: 'local_dsOrgUnitsLkp',
    viewName: 'sviw_System_OrgUnits',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "NamePath", type: "string" },
        {name: "IdPath", type: "string" },
        {name: "Parent_ID", type: "number"},
        {name: "UnitType", type: "string"},
        {name: "HasNodes", type: "bool"},
        {name: "NameAndTitle", type: "string"}]
},
local_dsSubProjects = {
    id: 'local_dsSubProjects',
    viewName: 'aviw_Complete_SubProjects',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" }]
},
local_dsMetaLookup = {
    id: 'local_dsMetaLookup',
    viewName: 'aviw_Assets_MetaObjectsWithHierarchi',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "TypeAndName", type:"string"},
        {name: "Description", type:"string"}]
},
local_dsMetaFields = {
    id: 'local_dsMetaFields',
    viewName: 'aviw_Assets_Setup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Meta1", type: "string"},
        {name: "Meta2", type: "string" },
        {name: "Meta3", type: "string" },
        {name: "Meta4", type: "string" },
        {name: "Meta5", type: "string" },
        {name: "Meta6", type: "string" },
        {name: "Meta7", type: "string" },
        {name: "Meta8", type: "string" },
        {name: "Meta9", type: "string" },
        {name: "Meta10", type: "string" },
        {name: "Meta11", type: "string" },
        {name: "Meta12", type: "string" },
        {name: "Meta13", type: "string" },
        {name: "Meta14", type: "string" },
        {name: "Meta15", type: "string" }]
},
local_dsRetentionCodes = {
    id: 'local_dsRetentionCodes',
    viewName: 'atbv_Arena_RetentionCodes',
    maxRecords: -1,
    whereClause: "Closed IS NULL",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Code", type: "string"},
        {name: "Description", type: "string" }]
},
local_dsVoidReasons = {
    id: 'local_dsVoidReasons',
    viewName: 'aviw_Arena_VoidReasons',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "VoidReason_ID", type: "number"}]
},
local_dsExtProcesses = {
    id: 'local_dsExtProcesses',
    viewName: 'atbv_Arena_ExternalProcesses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" }]
},
local_dsExtProStatuses = {
    id: 'local_dsExtProStatuses',
    viewName: 'atbv_Arena_ExternalProcessesStatuses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" }]
},
local_dsDocumentPackages = {
    id: 'local_dsDocumentPackages',
    viewName: 'aviw_Arena_DocumentPackagesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string" },
        {name: "ID", type: "number" }]
},
local_dsDistReqTypes = {
    id: 'local_dsDistReqTypes',
    viewName: 'atbv_Arena_DistributionRequirementTypes',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" }]
},
local_dsCompanies = {
    id: 'local_dsCompanies',
    viewName: 'sviw_System_Companies',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "ID", type: "number" },
        {name: "CompanyNo", type: "string" }]
},
local_dsPlannedChangeLkp = {
    id: 'local_dsPlannedChangeLkp',
    viewName: 'aviw_Arena_DocumentsPlannedChangeStatuses',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Description", type: "string" }]
},
local_dsCodeTypes = {
    id: 'local_dsCodeTypes',
    viewName: 'atbv_Arena_CodeTypes',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Sequence", type: "number", sortOrder: 1, sortDirection: "asc"}],
    
},
local_dsCodesLookup = {
    id: 'local_dsCodesLookup',
    viewName: 'aviw_Arena_CodesLookup2',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Seq", type: "string" },
        {name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}
